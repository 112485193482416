import { IDeleteButtonCell } from '@/components/cells/cellInterfaces';
import { IRoseAGGridColumn, dateTimeFormatter } from '@rose/common-ui';
import { IJwtTokenInfo } from '@rose/types';
import { authStore } from '@/state/authStore';

export function profilColumnsDefs(): IRoseAGGridColumn<IJwtTokenInfo>[] {
  return [
    {
      headerName: 'Info',
      field: 'device',
      flex: 1,
      resizable: true,
    },
    {
      headerName: 'Ausgestellt am',
      field: 'issuedAt',
      valueFormatter: dateTimeFormatter,
      sortable: true,
      sort: 'desc',
      width: 180,
      resizable: true,
    },
    {
      headerName: 'Ungültig ab',
      field: 'expiresAt',
      valueFormatter: dateTimeFormatter,
      sortable: true,
      width: 180,
      resizable: true,
    },
    {
      headerName: '',
      field: 'extid',
      cellRenderer: 'DeleteButtonCell',
      width: 40,
      cellStyle: { justifyContent: 'center' },
      cellRendererParams: {
        context: {
          async clicked(field: { column: string; row: IJwtTokenInfo }) {
            await authStore.dispatch.removeToken(field.row);
          },
        },
      } as IDeleteButtonCell<IJwtTokenInfo, string>,
    },
  ];
}
